/// 2018-19 data

import img13 from "./assests/images/events/2018-19/Swachata abhiyan/photo_album_1819_16.jpg";
// Army Law Inaugration Images
import Inimg1 from "./assests/images/events/2018-19/ALC Inaugartion/photo_album_1819_1 (1).jpg";
import Inimg2 from "./assests/images/events/2018-19/ALC Inaugartion/photo_album_1819_2 (1).jpg";
import Inimg3 from "./assests/images/events/2018-19/ALC Inaugartion/photo_album_1819_3 (1).jpg";
import Inimg4 from "./assests/images/events/2018-19/ALC Inaugartion/photo_album_1819_4 (1).jpg";
//Constitution day celebrations images
import Conimg1 from "./assests/images/events/2018-19/Constitution day/photo_album_1819_5.jpg";
import Conimg2 from "./assests/images/events/2018-19/Constitution day/photo_album_1819_6.jpg";
// District Court Visit images
import Disimg1 from "./assests/images/events/2018-19/District court visit/photo_album_1819_7.jpg";
import Disimg2 from "./assests/images/events/2018-19/District court visit/photo_album_1819_8.jpg";
//Constitution Of Moot Court Society Images
import DisMimg1 from "./assests/images/events/2018-19/Constitution of moot court/photo_album_1819_10.jpg";
import DisMimg2 from "./assests/images/events/2018-19/Constitution of moot court/photo_album_1819_9.jpg";
//Independence Day images
import Indimg1 from "./assests/images/events/2018-19/Independance day/photo_album_1819_11.jpg";
import Indimg2 from "./assests/images/events/2018-19/Independance day/photo_album_1819_12.jpg";

// Teacher's Day images
import Teaimg1 from "./assests/images/events/2018-19/Teachers day/6PTeachers_Day2018-1.jpg";
import Teaimg2 from "./assests/images/events/2018-19/Teachers day/6PTeachers_Day2018-2.jpg";
import Teaimg3 from "./assests/images/events/2018-19/Teachers day/6PTeachers_Day2018-3.jpg";
// Swachhata Abhiyan images
import swimg1 from "./assests/images/events/2018-19/Swachata abhiyan/photo_album_1819_15.jpg";
// import swimg2 from './assests/images/events/2018-20/Swachata abhiyan/photo_album_1819_16.jpg';
//College Picnic To Karla Caves images
import picimg1 from "./assests/images/events/2018-19/College trip/11P-Picnic-to-Karla-2.jpg";
import picimg2 from "./assests/images/events/2018-19/College trip/11P-Picnic-to-Karla-3.jpg";
import picimg3 from "./assests/images/events/2018-19/College trip/11P-Picnic-to-Karla-4.jpg";
import picimg4 from "./assests/images/events/2018-19/College trip/11P-Picnic-to-Karla-5.jpg";
import picimg5 from "./assests/images/events/2018-19/College trip/11P-Picnic-to-Karla-6.jpg";
import picimg6 from "./assests/images/events/2018-19/College trip/11P-Picnic-to-Karla-7.jpg";
import picimg7 from "./assests/images/events/2018-19/College trip/photo_album_1819_21.jpg";
// Unity Day Celebration  images
import udimg1 from "./assests/images/events/2018-19/Unity day celebration/12P-Unity-Day-Celebration-2018-addl-photo.jpg";
import udimg2 from "./assests/images/events/2018-19/Unity day celebration/photo_album_1819_22.jpg";

// 2019-20
//Adventure park trek images
import adimg1 from "./assests/images/events/2019-20/Adventure park trek/photo_album_1920_5.jpg";
import adimg2 from "./assests/images/events/2019-20/Adventure park trek/photo_album_1920_6.jpg";
import adimg3 from "./assests/images/events/2019-20/Adventure park trek/photo_album_1920_7.jpg";
import adimg4 from "./assests/images/events/2019-20/Adventure park trek/photo_album_1920_8.jpg";
//Constitution day celebration images
import cdimg1 from "./assests/images/events/2019-20/Constitution day celebration/photo_album_1920_38.jpg";
import cdimg2 from "./assests/images/events/2019-20/Constitution day celebration/photo_album_1920_39.jpg";
import cdimg3 from "./assests/images/events/2019-20/Constitution day celebration/photo_album_1920_40.jpg";
import cdimg4 from "./assests/images/events/2019-20/Constitution day celebration/photo_album_1920_41.jpg";
import cdimg5 from "./assests/images/events/2019-20/Constitution day celebration/photo_album_1920_42.jpg";
//ANNUAL SPORTS AND CULTURAL FEST images
import asimg1 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_57.jpg";
import asimg2 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_58.jpg";
import asimg3 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_59.jpg";
import asimg4 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_60.jpg";
import asimg5 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_61.jpg";
import asimg6 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_62.jpg";
import asimg7 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_63.jpg";
import asimg8 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_64.jpg";
import asimg9 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_65.jpg";
import asimg10 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_66.jpg";
import asimg11 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_67.jpg";
import asimg12 from "./assests/images/events/2019-20/ANNUAL SPORTS AND CULTURAL FEST/photo_album_1920_68.jpg";
//District court visit images
import dcimg1 from "./assests/images/events/2019-20/District court visit/photo_album_1920_37.jpg";
//Extrusion to sinhgad fort images
import eximg1 from "./assests/images/events/2019-20/Extrusion to sinhgad fort/photo_album_1920_27.jpg";
import eximg2 from "./assests/images/events/2019-20/Extrusion to sinhgad fort/photo_album_1920_28.jpg";
import eximg3 from "./assests/images/events/2019-20/Extrusion to sinhgad fort/photo_album_1920_29.jpg";
import eximg4 from "./assests/images/events/2019-20/Extrusion to sinhgad fort/photo_album_1920_30.jpg";
import eximg5 from "./assests/images/events/2019-20/Extrusion to sinhgad fort/photo_album_1920_31.jpg";
//Freshers picinic images
import fpimg1 from "./assests/images/events/2019-20/Freshers picinic/photo_album_1920_24.jpg";
import fpimg2 from "./assests/images/events/2019-20/Freshers picinic/photo_album_1920_25.jpg";
import fpimg3 from "./assests/images/events/2019-20/Freshers picinic/photo_album_1920_26.jpg";
//GUEST LECTURE ON BUSINESS DYNAMICS IN 21ST CENTURY
import glbimg1 from "./assests/images/events/2019-20/GUEST LECTURE ON BUSINESS DYNAMICS IN 21ST CENTURY/photo_album_1920_56.jpg";
//Guest lecture on carrier & life images
import glcimg1 from "./assests/images/events/2019-20/Guest lecture on carrier & life/photo_album_1920_22.jpg";
import glcimg2 from "./assests/images/events/2019-20/Guest lecture on carrier & life/photo_album_1920_23.jpg";
//GUEST LECTURE ON DEATH PENALTY IN INDIA
import gldimg1 from "./assests/images/events/2019-20/GUEST LECTURE ON DEATH PENALTY IN INDIA/photo_album_1920_54.jpg";
import gldimg2 from "./assests/images/events/2019-20/GUEST LECTURE ON DEATH PENALTY IN INDIA/photo_album_1920_55.jpg";
//GUEST LECTURE ON EFFECTIVE COMMUNICATION SKILLS
import gleimg1 from "./assests/images/events/2019-20/GUEST LECTURE ON EFFECTIVE COMMUNICATION SKILLS/photo_album_1920_52.jpg";
import gleimg2 from "./assests/images/events/2019-20/GUEST LECTURE ON EFFECTIVE COMMUNICATION SKILLS/photo_album_1920_53.jpg";
//GUEST LECTURE ON INTELLECTUAL PROPERTY
import gliimg1 from "./assests/images/events/2019-20/GUEST LECTURE ON INTELLECTUAL PROPERTY/photo_album_1920_50.jpg";
import gliimg2 from "./assests/images/events/2019-20/GUEST LECTURE ON INTELLECTUAL PROPERTY/photo_album_1920_51.jpg";
//Guest lecture on moot court preparation
import glmimg1 from "./assests/images/events/2019-20/Guest lecture on moot court preparation/photo_album_1920_36.jpg";
//Guest lecture on positive mental health
import glpimg1 from "./assests/images/events/2019-20/Guest lecture on positive mental health/photo_album_1920_10.jpg";
//Inaugaration of phase 2
import ipimg1 from "./assests/images/events/2019-20/Inaugaration of phase 2/photo_album_1920_15.jpg";
import ipimg2 from "./assests/images/events/2019-20/Inaugaration of phase 2/photo_album_1920_16.jpg";
import ipimg3 from "./assests/images/events/2019-20/Inaugaration of phase 2/photo_album_1920_17.jpg";
import ipimg4 from "./assests/images/events/2019-20/Inaugaration of phase 2/photo_album_1920_18.jpg";
//Independence day
import idimg1 from "./assests/images/events/2019-20/Independence day/photo_album_1920_1.jpg";
import idimg2 from "./assests/images/events/2019-20/Independence day/photo_album_1920_2.jpg";
import idimg3 from "./assests/images/events/2019-20/Independence day/photo_album_1920_3.jpg";
//Industrial visit to Mahindra rise
import ivimg1 from "./assests/images/events/2019-20/Industrial visit to Mahindra rise/photo_album_1920_35.jpg";
//INTRA COLLGE DEBATE
import icdimg1 from "./assests/images/events/2019-20/INTRA COLLGE DEBATE/photo_album_1920_32.jpg";
import icdimg2 from "./assests/images/events/2019-20/INTRA COLLGE DEBATE/photo_album_1920_33.jpg";
import icdimg3 from "./assests/images/events/2019-20/INTRA COLLGE DEBATE/photo_album_1920_34.jpg";
//National level elocution competition
import nlimg1 from "./assests/images/events/2019-20/National level elocution competition/photo_album_1920_43.jpg";
import nlimg2 from "./assests/images/events/2019-20/National level elocution competition/photo_album_1920_44.jpg";
import nlimg3 from "./assests/images/events/2019-20/National level elocution competition/photo_album_1920_45.jpg";
import nlimg4 from "./assests/images/events/2019-20/National level elocution competition/photo_album_1920_46.jpg";
import nlimg5 from "./assests/images/events/2019-20/National level elocution competition/photo_album_1920_47.jpg";
import nlimg6 from "./assests/images/events/2019-20/National level elocution competition/photo_album_1920_49.jpg";
//Seminar on corporate governance of india
import scimg1 from "./assests/images/events/2019-20/Seminar on corporate governance of india/photo_album_1920_19.jpg";
import scimg2 from "./assests/images/events/2019-20/Seminar on corporate governance of india/photo_album_1920_20.jpg";
import scimg3 from "./assests/images/events/2019-20/Seminar on corporate governance of india/photo_album_1920_21.jpg";
//Workshop on moot court
import wmimg1 from "./assests/images/events/2019-20/Workshop on moot court/photo_album_1920_9.jpg";
//Teachers day
import tdimg1 from "./assests/images/events/2019-20/Teachers day/photo_album_1920_11.jpg";
import tdimg2 from "./assests/images/events/2019-20/Teachers day/photo_album_1920_12.jpg";
import tdimg3 from "./assests/images/events/2019-20/Teachers day/photo_album_1920_13.jpg";
import tdimg4 from "./assests/images/events/2019-20/Teachers day/photo_album_1920_14.jpg";

//2020-21
//INAUGARATION OF ALCP BULLETIN images
import InAlimg1 from "./assests/images/events/2020-21/INAUGARATION OF ALCP BULLETIN/photo_album_1921_25.jpg";
import InAlimg2 from "./assests/images/events/2020-21/INAUGARATION OF ALCP BULLETIN/photo_album_1921_26.jpg";
import InAlimg3 from "./assests/images/events/2020-21/INAUGARATION OF ALCP BULLETIN/photo_album_1921_27.jpg";
//INTERVIEW WITH MS.PARINEETA DANDEKAR images
import Intimg1 from "./assests/images/events/2020-21/AN INTERVIEW WITH MS.PARINEETA DANDEKAR/photo_album_1921_6.jpg";
import Intimg2 from "./assests/images/events/2020-21/AN INTERVIEW WITH MS.PARINEETA DANDEKAR/photo_album_1921_7.jpg";
import Intimg3 from "./assests/images/events/2020-21/AN INTERVIEW WITH MS.PARINEETA DANDEKAR/photo_album_1921_8.jpg";
//LECTURE ON CONSTITUTIONAL RIGHTS TO BAIL-ISSUES AND CHALLENGES images
import Lcimg1 from "./assests/images/events/2020-21/EVENTS 2020-2021 »LECTURE ON CONSTITUTIONAL RIGHTS TO BAIL-ISSUES AND CHALLENGES/photo_album_1921_5.jpg";
//INSTITUTION OF RAJPUT REGIMENT imgesa
import Insimg1 from "./assests/images/events/2020-21/INSTITUTION OF RAJPUT REGIMENT/photo_album_1921_22.jpg";
import Insimg2 from "./assests/images/events/2020-21/INSTITUTION OF RAJPUT REGIMENT/photo_album_1921_23.jpg";
import Insimg3 from "./assests/images/events/2020-21/INSTITUTION OF RAJPUT REGIMENT/photo_album_1921_24.jpg";
//AN INTRA COLLEGE ELOCUTION COMPETtition images
import CCimg1 from "./assests/images/events/2020-21/ON 5THFEBRUARY 2021, AN INTRA COLLEGE ELOCUTION COMPETITION/photo_album_1921_12.jpg";
import CCimg2 from "./assests/images/events/2020-21/ON 5THFEBRUARY 2021, AN INTRA COLLEGE ELOCUTION COMPETITION/photo_album_1921_13.jpg";
import CCimg3 from "./assests/images/events/2020-21/ON 5THFEBRUARY 2021, AN INTRA COLLEGE ELOCUTION COMPETITION/photo_album_1921_14.jpg";
// MARATHI RAJBHASHA DIN images
import mrimg1 from "./assests/images/events/2020-21/ON 27TH FEBRUARY 2021 MARATHI RAJBHASHA DIN/photo_album_1921_15.jpg";
import mrimg2 from "./assests/images/events/2020-21/ON 27TH FEBRUARY 2021 MARATHI RAJBHASHA DIN/photo_album_1921_16.jpg";
import mrimg3 from "./assests/images/events/2020-21/ON 27TH FEBRUARY 2021 MARATHI RAJBHASHA DIN/photo_album_1921_17.jpg";
import mrimg4 from "./assests/images/events/2020-21/ON 27TH FEBRUARY 2021 MARATHI RAJBHASHA DIN/photo_album_1921_18.jpg";
//programme images
import primg1 from "./assests/images/events/2020-21/ON10TH MARCH 2021 A PROGRAMME WAS ORGANISED/photo_album_1921_19.jpg";
import primg2 from "./assests/images/events/2020-21/ON10TH MARCH 2021 A PROGRAMME WAS ORGANISED/photo_album_1921_20.jpg";
import primg3 from "./assests/images/events/2020-21/ON10TH MARCH 2021 A PROGRAMME WAS ORGANISED/photo_album_1921_21.jpg";
//ONLINE INTERACTION SESSION images
import oiimg1 from "./assests/images/events/2020-21/ONLINE INTERACTION SESSION OF 1ST YEAR STUDENTS WITH PRINCIPAL SIR/photo_album_1921_10.jpg";
import oiimg2 from "./assests/images/events/2020-21/ONLINE INTERACTION SESSION OF 1ST YEAR STUDENTS WITH PRINCIPAL SIR/photo_album_1921_11.jpg";
import oiimg3 from "./assests/images/events/2020-21/ONLINE INTERACTION SESSION OF 1ST YEAR STUDENTS WITH PRINCIPAL SIR/photo_album_1921_9.jpg";
//ONLINE WORKSHOP ON EBSCO images
import owimg1 from "./assests/images/events/2020-21/ONLINE WORKSHOP ON EBSCO/photo_album_1921_4.jpg";
// SECOND EDITION OF ALC PUNE NATIONAL ELOCUTION COMPETITION images
import seimg1 from "./assests/images/events/2020-21/SECOND EDITION OF ALC PUNE NATIONAL ELOCUTION COMPETITION/photo_album_1921_1.jpg";
import seimg2 from "./assests/images/events/2020-21/SECOND EDITION OF ALC PUNE NATIONAL ELOCUTION COMPETITION/photo_album_1921_2.jpg";
import seimg3 from "./assests/images/events/2020-21/SECOND EDITION OF ALC PUNE NATIONAL ELOCUTION COMPETITION/photo_album_1921_3.jpg";

//2021- 2022
//1ST NATIONAL MOOT COURT COMPETITION 2022
import mcimg1 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-1.jpg";
import mcimg2 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-2.jpg";
import mcimg3 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-3.jpg";
import mcimg4 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-4.jpg";
import mcimg5 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-5.jpg";
import mcimg6 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-6.jpg";
import mcimg7 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-7.jpg";
import mcimg8 from "./assests/images/events/2021-22/1ST NATIONAL MOOT COURT COMPETITION 2022/Moot-comp-8.jpg";
//INdependance day
import indimg1 from "./assests/images/events/2021-22/72ND INDEPENDENCE DAY CELEBRATED ON 15TH AUGUST, 2021/61-Independence-Day.jpg";
import indimg2 from "./assests/images/events/2021-22/72ND INDEPENDENCE DAY CELEBRATED ON 15TH AUGUST, 2021/62-Independence-Day.jpg";
import indimg3 from "./assests/images/events/2021-22/72ND INDEPENDENCE DAY CELEBRATED ON 15TH AUGUST, 2021/63-Independence-Day.jpg";
//Four day orientation programme
import fourimg1 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/50-Orientation-programme.jpg";
import fourimg2 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/51-Orientation-programme.jpg";
import fourimg3 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/52-Orientation-programme.jpg";
import fourimg4 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/53-Orientation-programme.jpg";
import fourimg5 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/54-Orientation-programme.jpg";
import fourimg6 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/55-Orientation-programme.jpg";
import fourimg7 from "./assests/images/events/2021-22/A FOUR-DAY ORIENTATION PROGRAMME FOR 2021-22 BATCH/56-Orientation-programme.jpg";
//adtventure trip
import adtimg1 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5318.jpg";
import adtimg2 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5331.jpg";
import adtimg3 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5349.jpg";
import adtimg4 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5369.jpg";
import adtimg5 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5406.jpg";
import adtimg6 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5416.jpg";
import adtimg7 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5428.jpg";
import adtimg8 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5436.jpg";
import adtimg9 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5451.jpg";
import adtimg10 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5486.jpg";
import adtimg11 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5710.jpg";
import adtimg12 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5712.jpg";
import adtimg13 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5719.jpg";
import adtimg14 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5755.jpg";
import adtimg15 from "./assests/images/events/2021-22/ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI/IMG_5781.jpg";
//ALc's Participation in legal aid
import laimg1 from "./assests/images/events/2021-22/ALC’S PARTICIPATION IN A LEGAL AID SEMINAR/IMG_1031.jpg";
import laimg2 from "./assests/images/events/2021-22/ALC’S PARTICIPATION IN A LEGAL AID SEMINAR/IMG_1046.jpg";
import laimg3 from "./assests/images/events/2021-22/ALC’S PARTICIPATION IN A LEGAL AID SEMINAR/IMG_1047.jpg";
import laimg4 from "./assests/images/events/2021-22/ALC’S PARTICIPATION IN A LEGAL AID SEMINAR/IMG_1052.jpg";
import laimg5 from "./assests/images/events/2021-22/ALC’S PARTICIPATION IN A LEGAL AID SEMINAR/IMG_1063.jpg";
//alternative dispute resolution
import ardimg1 from "./assests/images/events/2021-22/ALTERNATIVE DISPUTE RESOLUTION (ADR)/IMG_0623.jpg";
import ardimg2 from "./assests/images/events/2021-22/ALTERNATIVE DISPUTE RESOLUTION (ADR)/IMG_0624.jpg";
//Online guest lecture
import olgimg1 from "./assests/images/events/2021-22/AN ONLINE GUEST LECTURE ON - RECONSTRUCTING PERSONALITY/GL-105-PAWAN-SINGH.jpg";
//annual sports and cultural fest
import ascimg1 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7925.jpg";
import ascimg2 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7931.jpg";
import ascimg3 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7935.jpg";
import ascimg4 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7946.jpg";
import ascimg5 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7965.jpg";
import ascimg6 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7975.jpg";
import ascimg7 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_7993.jpg";
import ascimg8 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8109.jpg";
import ascimg9 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8118.jpg";
import ascimg10 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8124.jpg";
import ascimg11 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8152.jpg";
import ascimg12 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8242.jpg";
import ascimg13 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8246.jpg";
import ascimg14 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8249.jpg";
import ascimg15 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8285.jpg";
import ascimg16 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8298.jpg";
import ascimg17 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8319.jpg";
import ascimg18 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8521.jpg";
import ascimg19 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8526.jpg";
import ascimg20 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8551.jpg";
import ascimg21 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8554.jpg";
import ascimg22 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8565.jpg";
import ascimg23 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8582.jpg";
import ascimg24 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8807.jpg";
import ascimg25 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8809.jpg";
import ascimg26 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8822.jpg";
import ascimg27 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8825.jpg";
import ascimg28 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8867.jpg";
import ascimg29 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_8979.jpg";
import ascimg30 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9192.jpg";
import ascimg31 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9005.jpg";
import ascimg32 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9201.jpg";
import ascimg33 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9204.jpg";
import ascimg34 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9219.jpg";
import ascimg35 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9267.jpg";
import ascimg36 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9312.jpg";
import ascimg37 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9373.jpg";
import ascimg38 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9408.jpg";
import ascimg39 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9445.jpg";
import ascimg40 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9520.jpg";
import ascimg41 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9530.jpg";
import ascimg42 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9536.jpg";
import ascimg43 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9555.jpg";
import ascimg44 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9690.jpg";
import ascimg45 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9709.jpg";
import ascimg46 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9714.jpg";
import ascimg47 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9717.jpg";
import ascimg48 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9733.jpg";
import ascimg49 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9806.jpg";
import ascimg50 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9822.jpg";
import ascimg51 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9929.jpg";
import ascimg52 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9984.jpg";
import ascimg54 from "./assests/images/events/2021-22/ANNUAL SPORTS AND CULTURAL FEST 2022/IMG_9989.jpg";
//Carrer prospects as an officer
import cpimg1 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0226.jpg";
import cpimg2 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0230.jpg";
import cpimg3 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0240.jpg";
import cpimg4 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0264.jpg";
import cpimg5 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0284.jpg";
import cpimg6 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0292.jpg";
import cpimg7 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0317.jpg";
import cpimg8 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0321.jpg";
import cpimg9 from "./assests/images/events/2021-22/CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT/IMG_0345.jpg";
//Christmas day celebration
import cimg1 from "./assests/images/events/2021-22/CHRISTMAS DAY CELEBRATION/34-CHRISMAS-DAY.jpg";
import cimg2 from "./assests/images/events/2021-22/CHRISTMAS DAY CELEBRATION/35-CHRISMAS-DAY.jpg";
//COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE
import cdaimg1 from "./assests/images/events/2021-22/COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE/IMG_5068.jpg";
import cdaimg2 from "./assests/images/events/2021-22/COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE/IMG_5072.jpg";
import cdaimg3 from "./assests/images/events/2021-22/COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE/IMG_5078.jpg";
import cdaim4 from "./assests/images/events/2021-22/COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE/IMG_5087.jpg";
import cdaimg5 from "./assests/images/events/2021-22/COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE/IMG_5092.jpg";
//Constitution day celebration
import cdcimg1 from "./assests/images/events/2021-22/CONSTITUTION DAY CELEBRATION/Constitution-Day-2.jpg";
import cdcimg2 from "./assests/images/events/2021-22/CONSTITUTION DAY CELEBRATION/Constitution-Day-3.jpg";
import cdcimg3 from "./assests/images/events/2021-22/CONSTITUTION DAY CELEBRATION/Constitution-Day-4.jpg";
import cdcimg4 from "./assests/images/events/2021-22/CONSTITUTION DAY CELEBRATION/Constitution-Day-5.jpg";
import cdcimg5 from "./assests/images/events/2021-22/CONSTITUTION DAY CELEBRATION/Constitution-Day-7.jpg";
import cdcimg6 from "./assests/images/events/2021-22/CONSTITUTION DAY CELEBRATION/Constitution-day-1.jpg";
// court visit vadagaon
import cvimg1 from "./assests/images/events/2021-22/COURT VISIT AT VADAGAON/46-Vadgaon-court-visit.jpg";
import cvimg2 from "./assests/images/events/2021-22/COURT VISIT AT VADAGAON/47-Vadgaon-court-visit.jpg";
//boys hostel
import bhimg1 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-Hostel-Inougration-1.jpg";
import bhimg2 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-Hostel-Inougration-2.jpg";
import bhimg3 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-Hostel-Inougration-4.jpg";
import bhimg4 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-Hostel-Inougration-5.jpg";
import bhimg5 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-Hostel-Inougration-6.jpg";
import bhimg7 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-Hostel-Inougration-7.jpg";
import bhimg8 from "./assests/images/events/2021-22/EVENTS 2021-2022 »/Boys-hostel-Inougration-3.jpg";
//field visit at defence
import fvimg1 from "./assests/images/events/2021-22/FIELD VISIT ORGANISED AT ‘DEFENCE EXPO’/48-Field-vistit-expo.jpg";
import fvimg2 from "./assests/images/events/2021-22/FIELD VISIT ORGANISED AT ‘DEFENCE EXPO’/49-Field-vistit-expo.jpg";
//GUEST LECTURE ON ‘ARBITRATION AGREEMENT’
import glaimg1 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘ARBITRATION AGREEMENT’/IMG_0226.jpg";
import glaimg2 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘ARBITRATION AGREEMENT’/IMG_0230.jpg";
import glaimg3 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘ARBITRATION AGREEMENT’/IMG_0240.jpg";
import glaimg4 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘ARBITRATION AGREEMENT’/IMG_0264.jpg";
import glaimg5 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘ARBITRATION AGREEMENT’/IMG_0284.jpg";
import glaimg6 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘ARBITRATION AGREEMENT’/IMG_0292.jpg";
//GUEST LECTURE ON ‘CONTEMPT OF COURT THROUGH JUDICIAL LENS
import glccimg1 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘CONTEMPT OF COURT THROUGH JUDICIAL LENS/GL-112-Dr.-Medha-Kolhatkar.jpg";
//Guest lecture on Female genital
import glfgimg1 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’/IMG_0161.jpg";
import glfgimg2 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’/IMG_0163.jpg";
import glfimg3 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’/IMG_0164.jpg";
import glfimg4 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’/IMG_0165.jpg";
import glfimg5 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’/IMG_0167.jpg";
import glfimg6 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’/IMG_0179.jpg";
//GUEST LECTURE ON ‘START-UP FROM IDEA VALIDATION TO GETTING FINANCE
import glccimg2 from "./assests/images/events/2021-22/GUEST LECTURE ON ‘START-UP FROM IDEA VALIDATION TO GETTING FINANCE’/GL-104-CA-S.-Ibrahim.jpg";
//GUEST LECTURES ON ‘COMPETITION LAW
import glclimg1 from "./assests/images/events/2021-22/GUEST LECTURES ON ‘COMPETITION LAW/GL-110-Dr.-Swapnil-Bangali.jpg";
//GUEST LECTURES ON ‘FINANCIAL LITERACY
import glflimg1 from "./assests/images/events/2021-22/GUEST LECTURES ON ‘FINANCIAL LITERACY/GL-109-AMANPAL-SINGH.jpg";
//GUEST LECTURES ON ‘INTERNATIONAL ECONOMICS LAW
import glileimg1 from "./assests/images/events/2021-22/GUEST LECTURES ON ‘INTERNATIONAL ECONOMICS LAW/GL-110-Dr.-Swapnil-Bangali.jpg";
//INAUGURATION OF LAXMIBAI GIRLS HOSTEL
import igimg1 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/1-GIRLS-Hostel-Inougration.jpg";
import igimg2 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/10-GIRLS-Hostel-Inougration.jpg";
import igimg3 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/11-GIRLS-Hostel-Inougration.jpg";
import igimg4 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/12-GIRLS-Hostel-Inougration.jpg";
import igimg5 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/13-GIRLS-Hostel-Inougration.jpg";
import igimg6 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/14-GIRLS-Hostel-Inougration.jpg";
import igimg7 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/15-GIRLS-Hostel-Inougration.jpg";
import igimg8 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/16-GIRLS-Hostel-Inougration.jpg";
import igimg9 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/17-GIRLS-Hostel-Inougration.jpg";
import igimg10 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/18-GIRLS-Hostel-Inougration.jpg";
import igimg11 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/19-GIRLS-Hostel-Inougration.jpg";
import igimg12 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/20-GIRLS-Hostel-Inougration.jpg";
import igimg13 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/2-GIRLS-Hostel-Inougration.jpg";
import igimg14 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/3-GIRLS-Hostel-Inougration.jpg";
import igimg15 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/4-GIRLS-Hostel-Inougration.jpg";
import igimg16 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/5-GIRLS-Hostel-Inougration.jpg";
import igimg17 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/6-GIRLS-Hostel-Inougration.jpg";
import igimg18 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/7-GIRLS-Hostel-Inougration.jpg";
import igimg19 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/8-GIRLS-Hostel-Inougration.jpg";
import igimg20 from "./assests/images/events/2021-22/INAUGURATION OF LAXMIBAI GIRLS HOSTEL/9-GIRLS-Hostel-Inougration.jpg";
//INTERFACE BETWEEN IPR AND COMPETITION LAW
import iprimg1 from "./assests/images/events/2021-22/INTERFACE BETWEEN IPR AND COMPETITION LAW/IMG_1212.jpg";
import iprimg2 from "./assests/images/events/2021-22/INTERFACE BETWEEN IPR AND COMPETITION LAW/IMG_1222.jpg";
//INTRODUCTION TO RESEARCH METHODOLOGY
import rmimg1 from "./assests/images/events/2021-22/INTRODUCTION TO RESEARCH METHODOLOGY/GL-113-Yogesh-Wagh-Sir.jpg";
//LEGAL AID CAMP
import lacimg1 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6139.jpg";
import lacimg2 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6141.jpg";
import lacimg3 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6152.jpg";
import lacimg4 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6155.jpg";
import lacimg5 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6160.jpg";
import lacimg6 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6161.jpg";
import lacimg7 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6217.jpg";
import lacimg8 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6222.jpg";
import lacimg9 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6224.jpg";
import lacimg10 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6231.jpg";
import lacimg11 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6243.jpg";
import lacimg12 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6286.jpg";
import lacimg13 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6300.jpg";
import lacimg14 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6307.jpg";
import lacimg15 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6316.jpg";
import lacimg16 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6339.jpg";
import lacimg17 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6345.jpg";
import lacimg18 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6373.jpg";
import lacimg19 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6407.jpg";
import lacimg20 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6414.jpg";
import lacimg21 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6448.jpg";
import lacimg22 from "./assests/images/events/2021-22/LEGAL AID CAMP/IMG_6478.jpg";
//NATIONAL LOK ADALAT VISIT
import nlavimg1 from "./assests/images/events/2021-22/NATIONAL LOK ADALAT VISIT/IMG_5786.jpg";
//NTERNATIONAL WOMEN_S DAY’ CELEBRATION
import nwdimg1 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5178.jpg";
import nwdimg2 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5184.jpg";
import nwdimg3 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5193.jpg";
import nwdimg4 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5194.jpg";
import nwdimg5 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5213.jpg";
import nwdimg6 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5238.jpg";
import nwdimg7 from "./assests/images/events/2021-22/NTERNATIONAL WOMEN_S DAY’ CELEBRATION/IMG_5242.jpg";
//ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS
import opimg1 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0582.jpg";
import opimg2 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0584.jpg";
import opimg3 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0586.jpg";
import opimg4 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0594.jpg";
import opimg5 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0595.jpg";
import opimg6 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0596.jpg";
import opimg7 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0607.jpg";
import opimg8 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_0611.jpg";
import opimg9 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1066.jpg";
import opimg10 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1081.jpg";
import opimg11 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1094.jpg";
import opimg12 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1097.jpg";
import opimg13 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1118.jpg";
import opimg14 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1123.jpg";
import opimg15 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1124.jpg";
import opimg16 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1126.jpg";
import opimg17 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1129.jpg";
import opimg18 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1134.jpg";
import opimg19 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1155.jpg";
import opimg20 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1163.jpg";
import opimg21 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1167.jpg";
import opimg22 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1168.jpg";
import opimg23 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1183.jpg";
import opimg24 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 3RD AND 4TH YEAR STUDENTS/IMG_1190.jpg";
//ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS
import op4img1 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0478.jpg";
import op4img2 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0480.jpg";
import op4img3 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0487.jpg";
import op4img4 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0511.jpg";
import op4img5 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0514.jpg";
import op4img6 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0520.jpg";
import op4img7 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0535.jpg";
import op4img8 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0540.jpg";
import op4img9 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0562.jpg";
import op4img10 from "./assests/images/events/2021-22/ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS/IMG_0566.jpg";
//RIGHTS OF CULTURAL, LINGUISTIC AND RELIGIOUS MINORITIES
import rcrimg1 from "./assests/images/events/2021-22/RIGHTS OF CULTURAL, LINGUISTIC AND RELIGIOUS MINORITIES/IMG_0161.jpg";
import rcrimg2 from "./assests/images/events/2021-22/RIGHTS OF CULTURAL, LINGUISTIC AND RELIGIOUS MINORITIES/IMG_0173.jpg";
//UNPACKING STRESS AND ITS RELATIONSHIP WITH EGO
import usimg1 from "./assests/images/events/2021-22/UNPACKING STRESS AND ITS RELATIONSHIP WITH EGO/IMG_0959.jpg";
import usimg2 from "./assests/images/events/2021-22/UNPACKING STRESS AND ITS RELATIONSHIP WITH EGO/IMG_0986.jpg";
//VISIT TO DISTRICT LEGAL SERVICES AUTHORITY
import vdimg1 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0699.jpg";
import vdimg2 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0700.jpg";
import vdimg3 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0702.jpg";
import vdimg4 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0703.jpg";
import vdimg5 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0709.jpg";
import vdimg6 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0712.jpg";
import vdimg7 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0718.jpg";
import vdimg8 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0720.jpg";
import vdimg9 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0725.jpg";
import vdimg10 from "./assests/images/events/2021-22/VISIT TO DISTRICT LEGAL SERVICES AUTHORITY/IMG_0727.jpg";
//VISIT TO YERWADAJAIL
import vyimg1 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0430.jpg";
import vyimg2 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0431.jpg";
import vyimg3 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0433.jpg";
import vyimg4 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0434.jpg";
import vyimg5 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0435.jpg";
import vyimg6 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0436.jpg";
import vyimg7 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0438.jpg";
import vyimg8 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0439.jpg";
import vyimg9 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0440.jpg";
import vyimg10 from "./assests/images/events/2021-22/VISIT TO YERWADAJAIL/IMG_0441.jpg";
//WEBINAR ON ‘APPLICATION OF COPYRIGHT LAW IN TODAYS DIGITAL AGE
import waclimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘APPLICATION OF COPYRIGHT LAW IN TODAYS DIGITAL AGE/GL-90-Adv.-Gokul-Narayan.jpg";
//WEBINAR ON ‘CARRYING PORN ON MOBILE PHONE
import wcpimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘CARRYING PORN ON MOBILE PHONE/GL-100-Prashant-Mali.jpeg";
//WEBINAR ON ‘CONCEPT OF MEDICAL NEGLIGENCE UNDER TORT AND REMEDY UNDER CONSUMER PROTECTION LAW’
import wcmimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘CONCEPT OF MEDICAL NEGLIGENCE UNDER TORT AND REMEDY UNDER CONSUMER PROTECTION LAW’/GL-80-Adv.-Abhijit-Hartalkar.jpg";
//WEBINAR ON ‘CONSTITUTIONAL RIGHTS TO BAIL ISSUES AND CHALLENGES
import wcrimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘CONSTITUTIONAL RIGHTS TO BAIL ISSUES AND CHALLENGES/GL-87-Dushyant-Dave-sir.jpg";
//WEBINAR ON ‘CONTEMPORARY ISSUES AND TRENDS IN MARKETING
import wciimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘CONTEMPORARY ISSUES AND TRENDS IN MARKETING/GL-88-Dr.-Parul-Agrawal.jpg";
//WEBINAR ON ‘COPYRIGHT LAW FROM THE INDIAN PERSPECTIVE
import wcipimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘COPYRIGHT LAW FROM THE INDIAN PERSPECTIVE/GL-102-Adv.-Saurabh-Ganpatye.jpeg";
//WEBINAR ON ‘CURRICULUM VITAE MAKING
import wcvimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘CURRICULUM VITAE MAKING/GL-90-Adv.-Gokul-Narayan.jpg";
//WEBINAR ON ‘DEVELOPMENT OF CONSTITUTIONAL JURISPRUDENCE PHILOSOPHY OF JUDGES
import wdcimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘DEVELOPMENT OF CONSTITUTIONAL JURISPRUDENCE PHILOSOPHY OF JUDGES/GL-89-Adv.-Dr.-Sudhakar-Avhad.jpeg";
//WEBINAR ON ‘DIRECTIVE PRINCIPLES AND FUNDAMENTAL DUTIES
import wdpimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘DIRECTIVE PRINCIPLES AND FUNDAMENTAL DUTIES/GL-91-Prof.-Sourabh-Vasant-Chhaya-Ubale.jpeg";
//WEBINAR ON ‘FEDERAL STRUCTURE OF THE TEXTUAL CONSTITUTION OF INDIA
import fstimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘FEDERAL STRUCTURE OF THE TEXTUAL CONSTITUTION OF INDIA/GL-103-Prof.-Dr-K-L-Bhatia.jpeg";
//WEBINAR ON ‘FUNDAMENTALS OF RESEARCH METHODOLOGY
import wfrimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘FUNDAMENTALS OF RESEARCH METHODOLOGY/GL-84-Prof.-Dr.-Durgambini-Patel.jpg";
//WEBINAR ON ‘HINDU UNDIVIDED FAMILY AN OVERVIEW
import whuimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘HINDU UNDIVIDED FAMILY AN OVERVIEW/GL-78-Adv.-Bhushan-Kulkarni.jpg";
//WEBINAR ON ‘HOW TO PREPARE FOR NAAC
import whpimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘HOW TO PREPARE FOR NAAC/GL-76-Dr.-Parimala-Veluvali.jpeg";
//WEBINAR ON ‘IMPORTANCE OF VIRTUAL MEETING’
import wimimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘IMPORTANCE OF VIRTUAL MEETING’/GL-76-Dr.-Parimala-Veluvali.jpeg";
//WEBINAR ON ‘INTERNATIONAL LAW ASPECTS OF COUNTERING TERRORISM
import wicimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘INTERNATIONAL LAW ASPECTS OF COUNTERING TERRORISM/GL-96-Dr.-Aman-Mishra.jpeg";
//WEBINAR ON ‘INTESTATE SUCCESSION UNDER PERSONAL LAWS IN INDIA
import wipimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘INTESTATE SUCCESSION UNDER PERSONAL LAWS IN INDIA/GL-81-Adv.-Dr.-Milind-Hartalkar.jpg";
//WEBINAR ON ‘INTRODUCTION TO LEGAL RESEARCH WITH SCC ONLINE
import wlrimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘INTRODUCTION TO LEGAL RESEARCH WITH SCC ONLINE/GL-101-Mr.-Chetan-Singh-Gill.jpeg";
//WEBINAR ON ‘INTRODUCTION TO PENOLOGY AND VICTIMOLOGY’
import wipvimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘INTRODUCTION TO PENOLOGY AND VICTIMOLOGY’/GL-82-Dr.-Anand-Deshmukh.jpg";
//WEBINAR ON ‘LABOUR LAW REFORMS IN INDIA
import wllimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘LABOUR LAW REFORMS IN INDIA/GL-83-Adv.-Sandip-Nagarkar.jpg";
//WEBINAR ON ‘LAW RELATING TO GEOGRAPHICAL INDICATION (GI) IN INDIA’
import wlgimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘LAW RELATING TO GEOGRAPHICAL INDICATION (GI) IN INDIA’/GL-77-Prof.-Ganesh-Hingmire.jpg";
//WEBINAR ON ‘LEGAL DIMENSIONS OF CONTRACT
import wldimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘LEGAL DIMENSIONS OF CONTRACT/GL-97-Dr.-Praksh-Chaudhary.jpeg";
//WEBINAR ON ‘MAKE A CAREER IN LAW PREPARE FOR PLACEMENT
import wmcimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘MAKE A CAREER IN LAW PREPARE FOR PLACEMENT/GL-92-Adv.-Aneesh-Pathak.jpg";
//WEBINAR ON ‘MARRIAGE UNDER HINDU LAW
import wmuimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘MARRIAGE UNDER HINDU LAW/GL-99-Adv.-Latika-Salgaonkar.jpeg";
//WEBINAR ON ‘RELATIONSHIP BETWEEN INTERNATIONAL LAW AND MUNICIPAL LAW
import wrbimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘RELATIONSHIP BETWEEN INTERNATIONAL LAW AND MUNICIPAL LAW/GL-98-Rohit-Bokil.jpg";
//WEBINAR ON ‘STRATEGY OF MANAGING PERSONAL FINANCES INCLUDING MUTUAL FUNDS DURING AND AFTER COVID 19
import wmpimg1 from "./assests/images/events/2021-22/WEBINAR ON ‘STRATEGY OF MANAGING PERSONAL FINANCES INCLUDING MUTUAL FUNDS DURING AND AFTER COVID 19/GL-85-Mr.-Suryakant-Sharma.jpeg";
//WEBINAR ON ‘TRADE MARK LAW IN INDIA AN OVERVIEW
import wtmimh1 from "./assests/images/events/2021-22/WEBINAR ON ‘TRADE MARK LAW IN INDIA AN OVERVIEW/GL-79-Adv.-Shreedhar-Parundekar.jpg";
//Webinar on overview of Will
import wowimg1 from "./assests/images/events/2021-22/Webinar on overview of Will/GL-80-Adv.-Abhijit-Hartalkar.jpg";
//WEBINAR ON THE ABC OF INCOME TAX
import waiimg1 from "./assests/images/events/2021-22/WEBINAR ON THE ABC OF INCOME TAX/GL-75-Mr.-Pavankumar-V.-Zanvar.jpg";
//WORKSHOP ON ‘SOCIAL INTELLIGENCE
import wsiimg1 from "./assests/images/events/2021-22/WORKSHOP ON ‘SOCIAL INTELLIGENCE/workshop-on-social-intelligence-2.jpg";
import wsiimg2 from "./assests/images/events/2021-22/WORKSHOP ON ‘SOCIAL INTELLIGENCE/workshop-on-social-intelligence.jpg";
//YOGA DAY CELEBRATED ON 21ST JUNE
import ydimg1 from "./assests/images/events/2021-22/YOGA DAY CELEBRATED ON 21ST JUNE, 2021/64-Yoga-day.jpg";
import ydimg2 from "./assests/images/events/2021-22/YOGA DAY CELEBRATED ON 21ST JUNE, 2021/65-Yoga-day.jpg";

//2022- 2023
// 1ST INTER- COLLEGE MUN 2023
import InC1img23 from "./assests/images/events/2022-23/1ST INTER- COLLEGE MUN 2023/IMG_0734.jpg";
import InC2img23 from "./assests/images/events/2022-23/1ST INTER- COLLEGE MUN 2023/IMG_1327.jpg";

// A VISIT TO BOMBAY STOCK EXCHANGE WAS ORGANIZED ON 1ST MARCH 2023
import BOV1img23 from "./assests/images/events/2022-23/A VISIT TO BOMBAY STOCK EXCHANGE WAS ORGANIZED ON 1ST MARCH 2023/IMG_5329.jpg";
import BOV2img23 from "./assests/images/events/2022-23/A VISIT TO BOMBAY STOCK EXCHANGE WAS ORGANIZED ON 1ST MARCH 2023/IMG_5371.jpg";

//ARMY DAY CELEBRATION
import ARD1img23 from "./assests/images/events/2022-23/ARMY DAY CELEBRATION/21.jpg";
import ARD2img23 from "./assests/images/events/2022-23/ARMY DAY CELEBRATION/22.jpg";
import ARD3img23 from "./assests/images/events/2022-23/ARMY DAY CELEBRATION/23.jpg";
import ARD4img23 from "./assests/images/events/2022-23/ARMY DAY CELEBRATION/IMG_4114.jpg";
import ARD5img23 from "./assests/images/events/2022-23/ARMY DAY CELEBRATION/IMG_4133.jpg";

//ARMY LAW COLLEGE, PUNE ORGANISED THE ‘QUEST INDICIA’ 2ND NATIONAL MOOT COURT
import ALCQ1img23 from "./assests/images/events/2022-23/ARMY LAW COLLEGE, PUNE ORGANISED THE ‘QUEST INDICIA’ 2ND NATIONAL MOOT COURT/IMG_6073.jpg";
import ALCQ2img23 from "./assests/images/events/2022-23/ARMY LAW COLLEGE, PUNE ORGANISED THE ‘QUEST INDICIA’ 2ND NATIONAL MOOT COURT/IMG_6387.jpg";
import ALCQ3img23 from "./assests/images/events/2022-23/ARMY LAW COLLEGE, PUNE ORGANISED THE ‘QUEST INDICIA’ 2ND NATIONAL MOOT COURT/IMG_6427.jpg";

//BLOOD DONATION CAMP
import BLDC1img23 from "./assests/images/events/2022-23/BLOOD DONATION CAMP/29.jpg";
import BLDC2img23 from "./assests/images/events/2022-23/BLOOD DONATION CAMP/30.jpg";

//CELEBRATING CONSTITUTION DAY
import CCD1img23 from "./assests/images/events/2022-23/CELEBRATING CONSTITUTION DAY/3 (3).jpg";
import CCD2img23 from "./assests/images/events/2022-23/CELEBRATING CONSTITUTION DAY/4 (1).jpg";
import CCD3img23 from "./assests/images/events/2022-23/CELEBRATING CONSTITUTION DAY/5.jpg";
import CCD4img23 from "./assests/images/events/2022-23/CELEBRATING CONSTITUTION DAY/6 (1).jpg";

//FAREWELL OF 5TH YEAR STUDENTS (2018-2023 BATCH)
import FAW1img23 from "./assests/images/events/2022-23/FAREWELL OF 5TH YEAR STUDENTS (2018-2023 BATCH)/NMK_0308.jpg";
import FAW2img23 from "./assests/images/events/2022-23/FAREWELL OF 5TH YEAR STUDENTS (2018-2023 BATCH)/DSCF9119.jpg";
import FAW3img23 from "./assests/images/events/2022-23/FAREWELL OF 5TH YEAR STUDENTS (2018-2023 BATCH)/NMK_0344 (1).jpg";
import FAW4img23 from "./assests/images/events/2022-23/FAREWELL OF 5TH YEAR STUDENTS (2018-2023 BATCH)/NMK_0347.jpg";

//NSS CAMP
import NSS1img23 from "./assests/images/events/2022-23/NSS CAMP/DSC_8876.jpg";
import NSS2img23 from "./assests/images/events/2022-23/NSS CAMP/DSC_8986.jpg";
import NSS3img23 from "./assests/images/events/2022-23/NSS CAMP/DSC_9018.jpg";

//REPORT ON CRITICAL ANALYSIS OF RECENT TRENDS IN MARKETING
import RCA1img23 from "./assests/images/events/2022-23/REPORT ON CRITICAL ANALYSIS OF RECENT TRENDS IN MARKETING/IMG_6627.jpg";
import RCA2img23 from "./assests/images/events/2022-23/REPORT ON CRITICAL ANALYSIS OF RECENT TRENDS IN MARKETING/IMG_6634.jpg";

//THE ART OF NETWORKING AND WRITING EFFECTIVE EMAILS TO LAND JOBS
import ANW1img23 from "./assests/images/events/2022-23/THE ART OF NETWORKING AND WRITING EFFECTIVE EMAILS TO LAND JOBS/IMG_5241.jpg";
import ANW2img23 from "./assests/images/events/2022-23/THE ART OF NETWORKING AND WRITING EFFECTIVE EMAILS TO LAND JOBS/IMG_5250.jpg";

//VISIT BY CHIEF OF ARMY STAFF
import VCAS1img23 from "./assests/images/events/2022-23/VISIT BY CHIEF OF ARMY STAFF/IMG_1631.jpg";
import VCAS2img23 from "./assests/images/events/2022-23/VISIT BY CHIEF OF ARMY STAFF/IMG_1652.jpg";

//VISIT TO RBI MUSEUM AND BSE
import VRBI1img23 from "./assests/images/events/2022-23/VISIT TO RBI MUSEUM AND BSE/RBI-BSE-Visit-2.jpg";
import VRBI2img23 from "./assests/images/events/2022-23/VISIT TO RBI MUSEUM AND BSE/RBI-BSE-Visit-1.jpg";

//YOGA DAY CELEBRATION
import YOGA1img23 from "./assests/images/events/2022-23/YOGA DAY CELEBRATION/IMG_3075.jpg";
import YOGA2img23 from "./assests/images/events/2022-23/YOGA DAY CELEBRATION/IMG_3098.jpg";
import YOGA3img23 from "./assests/images/events/2022-23/YOGA DAY CELEBRATION/IMG_3099.jpg";

// Symposium on “Pathway to Success for Aspiring Lawyers in the Contemporary Era Symposium”
import symp1img24 from "./assests/images/events/2023-24/Symposium/IMG_9864.JPG";
import symp2img24 from "./assests/images/events/2023-24/Symposium/IMG_9877.JPG";
import symp3img24 from "./assests/images/events/2023-24/Symposium/IMG_9882.JPG";
import symp4img24 from "./assests/images/events/2023-24/Symposium/IMG_9903.JPG";
import symp5img24 from "./assests/images/events/2023-24/Symposium/IMG_9932.JPG";
import symp6img24 from "./assests/images/events/2023-24/Symposium/NMK_5179.JPG";

// Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 2024
import field1img24 from "./assests/images/events/2023-24/Field Marshal SHFJ/NMK_5142.JPG";
import field2img24 from "./assests/images/events/2023-24/Field Marshal SHFJ/NMK_5154.JPG";
import field3img24 from "./assests/images/events/2023-24/Field Marshal SHFJ/NMK_5162.JPG";
import field4img24 from "./assests/images/events/2023-24/Field Marshal SHFJ/NMK_5175.JPG";
import field5img24 from "./assests/images/events/2023-24/Field Marshal SHFJ/NMK_5179.JPG";

// RELEASE OF JALCP  (TATVAVEDA)
import tatvaveda1img24 from "./assests/images/events/2023-24/RELEASE OF JALCP/Picture1.jpg";
import tatvaveda2img24 from "./assests/images/events/2023-24/RELEASE OF JALCP/Picture2.jpg";
import tatvaveda3img24 from "./assests/images/events/2023-24/RELEASE OF JALCP/Picture3.jpg";
import tatvaveda4img24 from "./assests/images/events/2023-24/RELEASE OF JALCP/Picture4.jpg";

export const dropdownList = [
  {
    id: 1,
    year: "2023-24",
  },
  {
    id: 2,
    year: "2022-23",
  },
  {
    id: 3,
    year: "2021-22",
  },
  {
    id: 4,
    year: "2020-21",
  },
  {
    id: 5,
    year: "2019-20",
  },
  {
    id: 6,
    year: "2018-19",
  },
];

export const Eventsdata = [
  {
    id: 191,
    img: Inimg1,
    name: "Army Law College Inauguration Ceremony",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Army Law College Inauguration Ceremony",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Inimg1, Inimg2, Inimg3, Inimg4],
      },
    ],
  },
  {
    id: 192,
    img: Conimg1,
    name: "Constitution Day Celebration",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Constitution Day Celebration 26/11/2018",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Conimg1, Conimg2],
      },
    ],
  },
  {
    id: 193,
    img: Disimg1,
    name: "District Court Visit",
    year: "2018-19",
    relatedEvents: [
      {
        name: "District Court Visit ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Disimg1, Disimg2],
      },
    ],
  },
  {
    id: 194,
    img: DisMimg1,
    name: "Constitution Of Moot Court Society ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Constitution Of Moot Court Society ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [DisMimg1, DisMimg2],
      },
    ],
  },
  {
    id: 195,
    img: Indimg1,
    name: "Independence Day ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Independence Day ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Indimg1, Indimg2],
      },
    ],
  },
  {
    id: 196,
    img: Teaimg1,
    name: "Teacher's Day ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Teacher's Day ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Teaimg1, Teaimg2, Teaimg3],
      },
    ],
  },
  {
    id: 197,
    img: img13,
    name: "Swachhata Abhiyan",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Swachhata Abhiyan ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [swimg1, img13],
      },
    ],
  },
  {
    id: 198,
    img: Conimg2,
    name: "Constitution Day Celebration",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Constitution Day Celebration ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Conimg1, Conimg2],
      },
    ],
  },
  {
    id: 199,
    img: Disimg2,
    name: "District Court Visit ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "District Court Visit ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Disimg1, Disimg2],
      },
    ],
  },
  {
    id: 1910,
    img: picimg1,
    name: "College Picnic To Karla Caves",
    year: "2018-19",
    relatedEvents: [
      {
        name: "College Picnic To Karla Caves",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [picimg1, picimg2, picimg3, picimg4, picimg5, picimg6, picimg7],
      },
    ],
  },
  {
    id: 1911,
    img: udimg1,
    name: "Unity Day Celebration ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Unity Day Celebration ",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [udimg1, udimg2],
      },
    ],
  },
  {
    id: 1912,
    img: Disimg1,
    name: "District Court Visit ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "District Court Visit",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Disimg1, Disimg2],
      },
    ],
  },
  {
    id: 1913,
    img: img13,
    name: "Swachhata Abhiyan ",
    year: "2018-19",
    relatedEvents: [
      {
        name: "Swachhata Abhiyan",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [swimg1, img13],
      },
    ],
  },
  // 2019-20
  {
    id: 201,
    img: adimg1,
    name: "Adventure trek",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Adventure trek",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [adimg1, adimg2, adimg3, adimg4],
      },
    ],
  },
  {
    id: 202,
    img: asimg1,
    name: "ANNUAL SPORTS AND CULTURAL FEST",
    year: "2019-20",
    relatedEvents: [
      {
        name: "ANNUAL SPORTS AND CULTURAL FEST",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          asimg1,
          asimg2,
          asimg3,
          asimg4,
          asimg5,
          asimg6,
          asimg7,
          asimg8,
          asimg9,
          asimg10,
          asimg11,
          asimg12,
        ],
      },
    ],
  },
  {
    id: 203,
    img: cdimg1,
    name: "Constitution day celebration",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Constitution day celebration",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [cdimg1, cdimg2, cdimg3, cdimg4, cdimg5],
      },
    ],
  },
  {
    id: 204,
    img: dcimg1,
    name: "District court visit",
    year: "2019-20",
    relatedEvents: [
      {
        name: "District court visit",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [dcimg1],
      },
    ],
  },
  {
    id: 205,
    img: eximg1,
    name: "Extrusion to sinhgad fort",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Extrusion to sinhgad fort",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [eximg1, eximg2, eximg3, eximg4, eximg5],
      },
    ],
  },
  {
    id: 206,
    img: fpimg1,
    name: "Freshers picinic",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Freshers picinic",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [fpimg1, fpimg2, fpimg3],
      },
    ],
  },
  {
    id: 207,
    img: glbimg1,
    name: "GUEST LECTURE ON BUSINESS DYNAMICS IN 21ST CENTURY",
    year: "2019-20",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON BUSINESS DYNAMICS IN 21ST CENTURY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glbimg1],
      },
    ],
  },
  {
    id: 208,
    img: glcimg1,
    name: "Guest lecture on carrier & life",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Guest lecture on carrier & life",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glcimg1, glcimg2],
      },
    ],
  },
  {
    id: 209,
    img: gldimg1,
    name: "GUEST LECTURE ON DEATH PENALTY IN INDIA",
    year: "2019-20",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON DEATH PENALTY IN INDIA",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [gldimg1, gldimg2],
      },
    ],
  },
  {
    id: 2010,
    img: gleimg1,
    name: "GUEST LECTURE ON EFFECTIVE COMMUNICATION SKILLS",
    year: "2019-20",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON EFFECTIVE COMMUNICATION SKILLS",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [gleimg1, gleimg2],
      },
    ],
  },
  {
    id: 2011,
    img: gliimg1,
    name: "GUEST LECTURE ON INTELLECTUAL PROPERTY",
    year: "2019-20",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON INTELLECTUAL PROPERTY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [gliimg1, gliimg2],
      },
    ],
  },
  {
    id: 2012,
    img: glmimg1,
    name: "Guest lecture on moot court preparation",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Guest lecture on moot court preparation",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glmimg1],
      },
    ],
  },
  {
    id: 2013,
    img: glpimg1,
    name: "Guest lecture on positive mental health",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Guest lecture on positive mental health",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glpimg1],
      },
    ],
  },
  {
    id: 2014,
    img: ipimg1,
    name: "Inaugaration of phase 2",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Inaugaration of phase 2",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ipimg1, ipimg2, ipimg3, ipimg4],
      },
    ],
  },
  {
    id: 2015,
    img: idimg1,
    name: "Independence day",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Independence day",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [idimg1, idimg2, idimg3],
      },
    ],
  },
  {
    id: 2016,
    img: ivimg1,
    name: "Industrial visit to Mahindra rise",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Industrial visit to Mahindra rise",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ivimg1],
      },
    ],
  },
  {
    id: 2017,
    img: icdimg1,
    name: "INTRA COLLGE DEBATE",
    year: "2019-20",
    relatedEvents: [
      {
        name: "INTRA COLLGE DEBATE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [icdimg1, icdimg2, icdimg3],
      },
    ],
  },
  {
    id: 2018,
    img: nlimg1,
    name: "National level elocution competition",
    year: "2019-20",
    relatedEvents: [
      {
        name: "National level elocution competition",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [nlimg1, nlimg2, nlimg3, nlimg4, nlimg5, nlimg6],
      },
    ],
  },
  {
    id: 2019,
    img: scimg1,
    name: "Seminar on corporate governance of india",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Seminar on corporate governance of india",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [scimg1, scimg2, scimg3],
      },
    ],
  },
  {
    id: 2020,
    img: tdimg1,
    name: "Teachers day",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Teachers day",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [tdimg1, tdimg2, tdimg3, tdimg4],
      },
    ],
  },
  {
    id: 2021,
    img: wmimg1,
    name: "Workshop on moot court",
    year: "2019-20",
    relatedEvents: [
      {
        name: "Workshop on moot court",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wmimg1],
      },
    ],
  },
  // 2020-2021
  {
    id: 211,
    img: InAlimg1,
    name: "INAUGARATION OF ALCP BULLETIN",
    year: "2020-21",
    relatedEvents: [
      {
        name: "INAUGARATION OF ALCP BULLETIN",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [InAlimg1, InAlimg2, InAlimg3],
      },
    ],
  },
  {
    id: 212,
    img: Intimg1,
    name: "AN INTERVIEW WITH MS.PARINEETA DANDEKAR",
    year: "2020-21",
    relatedEvents: [
      {
        name: "AN INTERVIEW WITH MS.PARINEETA DANDEKAR",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Intimg1, Intimg2, Intimg3],
      },
    ],
  },
  {
    id: 213,
    img: Lcimg1,
    name: "LECTURE ON CONSTITUTIONAL RIGHTS TO BAIL-ISSUES AND CHALLENGES",
    year: "2020-21",
    relatedEvents: [
      {
        name: "LECTURE ON CONSTITUTIONAL RIGHTS TO BAIL-ISSUES AND CHALLENGES",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Lcimg1],
      },
    ],
  },
  {
    id: 214,
    img: Insimg1,
    name: "INSTITUTION OF RAJPUT REGIMENT",
    year: "2020-21",
    relatedEvents: [
      {
        name: "INSTITUTION OF RAJPUT REGIMENT",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [Insimg1, Insimg2, Insimg3],
      },
    ],
  },
  {
    id: 215,
    img: CCimg1,
    name: " AN INTRA COLLEGE ELOCUTION COMPETITION",
    year: "2020-21",
    relatedEvents: [
      {
        name: "AN INTRA COLLEGE ELOCUTION COMPETITION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [CCimg1, CCimg2, CCimg3],
      },
    ],
  },
  {
    id: 216,
    img: mrimg1,
    name: " MARATHI RAJBHASHA DIN",
    year: "2020-21",
    relatedEvents: [
      {
        name: "MARATHI RAJBHASHA DIN",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [mrimg1, mrimg2, mrimg3, mrimg4],
      },
    ],
  },
  {
    id: 217,
    img: primg1,
    name: " A PROGRAMME WAS ORGANISED",
    year: "2020-21",
    relatedEvents: [
      {
        name: "A PROGRAMME WAS ORGANISED",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [primg1, primg2, primg3],
      },
    ],
  },
  {
    id: 218,
    img: oiimg1,
    name: "ONLINE INTERACTION SESSION OF 1ST YEAR STUDENTS WITH PRINCIPAL SIR",
    year: "2020-21",
    relatedEvents: [
      {
        name: "ONLINE INTERACTION SESSION OF 1ST YEAR STUDENTS WITH PRINCIPAL SIR",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [oiimg1, oiimg2, oiimg3],
      },
    ],
  },
  {
    id: 219,
    img: owimg1,
    name: "ONLINE WORKSHOP ON EBSCO",
    year: "2020-21",
    relatedEvents: [
      {
        name: "ONLINE WORKSHOP ON EBSCO",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [owimg1],
      },
    ],
  },
  {
    id: 210,
    img: seimg1,
    name: "SECOND EDITION OF ALC PUNE NATIONAL ELOCUTION COMPETITION",
    year: "2020-21",
    relatedEvents: [
      {
        name: "SECOND EDITION OF ALC PUNE NATIONAL ELOCUTION COMPETITION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [seimg1, seimg2, seimg3],
      },
    ],
  },

  // 2021-22
  {
    id: 221,
    img: mcimg1,
    name: "1ST NATIONAL MOOT COURT COMPETITION ",
    year: "2021-22",
    relatedEvents: [
      {
        name: "1ST NATIONAL MOOT COURT COMPETITION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          mcimg1,
          mcimg2,
          mcimg3,
          mcimg4,
          mcimg5,
          mcimg6,
          mcimg7,
          mcimg8,
        ],
      },
    ],
  },
  {
    id: 222,
    img: indimg1,
    name: "Independance day ",
    year: "2021-22",
    relatedEvents: [
      {
        name: "Independance day",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [indimg1, indimg2, indimg3],
      },
    ],
  },
  {
    id: 223,
    img: fourimg1,
    name: "Four day orientation programme ",
    year: "2021-22",
    relatedEvents: [
      {
        name: "Four day orientation programme",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          fourimg1,
          fourimg2,
          fourimg3,
          fourimg4,
          fourimg5,
          fourimg6,
          fourimg7,
        ],
      },
    ],
  },
  {
    id: 224,
    img: adtimg1,
    name: "ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI",
    year: "2021-22",
    relatedEvents: [
      {
        name: "ADVENTURE TRIP TO VELOCITY ENTERTAINMENTS PANCHGANI",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          adtimg1,
          adtimg2,
          adtimg3,
          adtimg4,
          adtimg5,
          adtimg6,
          adtimg7,
          adtimg8,
          adtimg9,
          adtimg10,
          adtimg11,
          adtimg12,
          adtimg13,
          adtimg14,
          adtimg15,
        ],
      },
    ],
  },
  {
    id: 225,
    img: laimg1,
    name: "ALC’S PARTICIPATION IN A LEGAL AID SEMINAR",
    year: "2021-22",
    relatedEvents: [
      {
        name: "ALC’S PARTICIPATION IN A LEGAL AID SEMINAR",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [laimg1, laimg2, laimg3, laimg4, laimg5],
      },
    ],
  },
  {
    id: 226,
    img: ardimg1,
    name: "ALTERNATIVE DISPUTE RESOLUTION (ADR)",
    year: "2021-22",
    relatedEvents: [
      {
        name: "ALTERNATIVE DISPUTE RESOLUTION (ADR)",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ardimg1, ardimg2],
      },
    ],
  },
  {
    id: 227,
    img: olgimg1,
    name: "AN ONLINE GUEST LECTURE ON - RECONSTRUCTING PERSONALITY",
    year: "2021-22",
    relatedEvents: [
      {
        name: "AN ONLINE GUEST LECTURE ON - RECONSTRUCTING PERSONALITY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [olgimg1],
      },
    ],
  },
  {
    id: 228,
    img: ascimg1,
    name: "ANNUAL SPORTS AND CULTURAL FEST 2022",
    year: "2021-22",
    relatedEvents: [
      {
        name: "ANNUAL SPORTS AND CULTURAL FEST 2022",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          ascimg1,
          ascimg2,
          ascimg3,
          ascimg4,
          ascimg5,
          ascimg6,
          ascimg7,
          ascimg8,
          ascimg9,
          ascimg10,
          ascimg11,
          ascimg12,
          ascimg13,
          ascimg14,
          ascimg15,
          ascimg16,
          ascimg17,
          ascimg18,
          ascimg19,
          ascimg20,
          ascimg21,
          ascimg22,
          ascimg23,
          ascimg24,
          ascimg25,
          ascimg26,
          ascimg27,
          ascimg28,
          ascimg29,
          ascimg30,
          ascimg31,
          ascimg32,
          ascimg33,
          ascimg34,
          ascimg35,
          ascimg36,
          ascimg37,
          ascimg38,
          ascimg39,
          ascimg40,
          ascimg41,
          ascimg42,
          ascimg43,
          ascimg44,
          ascimg45,
          ascimg46,
          ascimg47,
          ascimg48,
          ascimg49,
          ascimg50,
          ascimg51,
          ascimg52,
          ascimg54,
        ],
      },
    ],
  },

  {
    id: 229,
    img: cpimg1,
    name: "CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT",
    year: "2021-22",
    relatedEvents: [
      {
        name: "CAREER PROSPECTS AS AN OFFICER OF JAG’S DEPARTMENT",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          cpimg1,
          cpimg2,
          cpimg3,
          cpimg4,
          cpimg5,
          cpimg6,
          cpimg7,
          cpimg8,
          cpimg9,
        ],
      },
    ],
  },
  {
    id: 2210,
    img: cimg1,
    name: "CHRISTMAS DAY CELEBRATION",
    year: "2021-22",
    relatedEvents: [
      {
        name: "CHRISTMAS DAY CELEBRATION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [cimg1, cimg2],
      },
    ],
  },
  {
    id: 2211,
    img: cdaimg1,
    name: "COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "COMMEMORATION OF THE DEATH ANNIVERSARY OF KRANTIJYOTI SAVITRIBAI PHULE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [cdaimg1, cdaimg2, cdaimg3, cdaim4, cdaimg5],
      },
    ],
  },
  {
    id: 2212,
    img: cdcimg1,
    name: "CONSTITUTION DAY CELEBRATION",
    year: "2021-22",
    relatedEvents: [
      {
        name: "CONSTITUTION DAY CELEBRATION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [cdcimg1, cdcimg2, cdcimg3, cdcimg4, cdcimg5, cdcimg6],
      },
    ],
  },
  {
    id: 2213,
    img: cvimg1,
    name: "COURT VISIT AT VADAGAON",
    year: "2021-22",
    relatedEvents: [
      {
        name: "COURT VISIT AT VADAGAON",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [cvimg1, cvimg2],
      },
    ],
  },
  {
    id: 2214,
    img: bhimg1,
    name: "Boys Hostel Inauguration",
    year: "2021-22",
    relatedEvents: [
      {
        name: "Boys Hostel Inauguration",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [bhimg1, bhimg2, bhimg3, bhimg4, bhimg5, bhimg7, bhimg8],
      },
    ],
  },
  {
    id: 2215,
    img: fvimg1,
    name: "FIELD VISIT ORGANISED AT ‘DEFENCE EXPO’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "FIELD VISIT ORGANISED AT ‘DEFENCE EXPO’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [fvimg1, fvimg2],
      },
    ],
  },
  {
    id: 2216,
    img: glaimg1,
    name: "GUEST LECTURE ON ‘ARBITRATION AGREEMENT’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON ‘ARBITRATION AGREEMENT’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glaimg1, glaimg2, glaimg3, glaimg4, glaimg5, glaimg6],
      },
    ],
  },
  {
    id: 2217,
    img: glccimg1,
    name: "GUEST LECTURE ON ‘CONTEMPT OF COURT THROUGH JUDICIAL LENS",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON ‘CONTEMPT OF COURT THROUGH JUDICIAL LENS",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glccimg1],
      },
    ],
  },
  {
    id: 2218,
    img: glfgimg1,
    name: "GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON ‘FEMALE GENITAL MUTILATION’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glfgimg1, glfgimg2, glfimg3, glfimg4, glfimg5, glfimg6],
      },
    ],
  },
  {
    id: 2219,
    img: glccimg2,
    name: "GUEST LECTURE ON ‘START-UP FROM IDEA VALIDATION TO GETTING FINANCE’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURE ON ‘START-UP FROM IDEA VALIDATION TO GETTING FINANCE’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glccimg2],
      },
    ],
  },
  {
    id: 2220,
    img: glclimg1,
    name: "GUEST LECTURES ON ‘COMPETITION LAW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURES ON ‘COMPETITION LAW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glclimg1],
      },
    ],
  },
  {
    id: 2221,
    img: glflimg1,
    name: "GUEST LECTURES ON ‘FINANCIAL LITERACY",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURES ON ‘FINANCIAL LITERACY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glflimg1],
      },
    ],
  },
  {
    id: 2222,
    img: glileimg1,
    name: "GUEST LECTURES ON ‘INTERNATIONAL ECONOMICS LAW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "GUEST LECTURES ON ‘INTERNATIONAL ECONOMICS LAW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [glileimg1],
      },
    ],
  },
  {
    id: 2223,
    img: igimg1,
    name: "INAUGURATION OF LAXMIBAI GIRLS HOSTEL",
    year: "2021-22",
    relatedEvents: [
      {
        name: "INAUGURATION OF LAXMIBAI GIRLS HOSTEL",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          igimg1,
          igimg2,
          igimg3,
          igimg4,
          igimg5,
          igimg6,
          igimg7,
          igimg8,
          igimg9,
          igimg10,
          igimg11,
          igimg12,
          igimg13,
          igimg14,
          igimg15,
          igimg16,
          igimg17,
          igimg18,
          igimg19,
          igimg20,
        ],
      },
    ],
  },
  {
    id: 2224,
    img: iprimg1,
    name: "INTERFACE BETWEEN IPR AND COMPETITION LAW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "INTERFACE BETWEEN IPR AND COMPETITION LAW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [iprimg1, iprimg2],
      },
    ],
  },
  {
    id: 2225,
    img: rmimg1,
    name: "INTRODUCTION TO RESEARCH METHODOLOGY",
    year: "2021-22",
    relatedEvents: [
      {
        name: "INTRODUCTION TO RESEARCH METHODOLOGY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [rmimg1],
      },
    ],
  },
  {
    id: 2226,
    img: lacimg1,
    name: "LEGAL AID CAMP",
    year: "2021-22",
    relatedEvents: [
      {
        name: "LEGAL AID CAMP",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          lacimg1,
          lacimg2,
          lacimg3,
          lacimg4,
          lacimg5,
          lacimg6,
          lacimg7,
          lacimg8,
          lacimg9,
          lacimg10,
          lacimg11,
          lacimg12,
          lacimg13,
          lacimg14,
          lacimg15,
          lacimg16,
          lacimg17,
          lacimg18,
          lacimg19,
          lacimg20,
          lacimg21,
          lacimg22,
        ],
      },
    ],
  },
  {
    id: 2227,
    img: nlavimg1,
    name: "NATIONAL LOK ADALAT VISIT",
    year: "2021-22",
    relatedEvents: [
      {
        name: "NATIONAL LOK ADALAT VISIT",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [nlavimg1],
      },
    ],
  },
  {
    id: 2228,
    img: nwdimg1,
    name: "NTERNATIONAL WOMEN_S DAY’ CELEBRATION",
    year: "2021-22",
    relatedEvents: [
      {
        name: "NTERNATIONAL WOMEN_S DAY’ CELEBRATION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [nwdimg1, nwdimg2, nwdimg3, nwdimg4, nwdimg5, nwdimg6, nwdimg7],
      },
    ],
  },
  {
    id: 2229,
    img: opimg1,
    name: "ORIENTATION PROGRAMME BY 1RD AND 4TH YEAR STUDENTS",
    year: "2021-22",
    relatedEvents: [
      {
        name: "ORIENTATION PROGRAMME BY 1RD AND 4TH YEAR STUDENTS",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          opimg1,
          opimg2,
          opimg3,
          opimg4,
          opimg5,
          opimg6,
          opimg7,
          opimg8,
          opimg9,
          opimg10,
          opimg11,
          opimg12,
          opimg13,
          opimg14,
          opimg15,
          opimg16,
          opimg17,
          opimg18,
          opimg19,
          opimg20,
          opimg21,
          opimg22,
          opimg23,
          opimg24,
        ],
      },
    ],
  },
  {
    id: 2230,
    img: op4img1,
    name: "ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS",
    year: "2021-22",
    relatedEvents: [
      {
        name: "ORIENTATION PROGRAMME BY 4TH YEAR STUDENTS",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          op4img1,
          op4img2,
          op4img3,
          op4img4,
          op4img5,
          op4img6,
          op4img7,
          op4img8,
          op4img9,
          op4img10,
        ],
      },
    ],
  },
  {
    id: 2231,
    img: rcrimg1,
    name: "RIGHTS OF CULTURAL, LINGUISTIC AND RELIGIOUS MINORITIES",
    year: "2021-22",
    relatedEvents: [
      {
        name: "RIGHTS OF CULTURAL, LINGUISTIC AND RELIGIOUS MINORITIES",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [rcrimg1, rcrimg2],
      },
    ],
  },
  {
    id: 2232,
    img: usimg1,
    name: "UNPACKING STRESS AND ITS RELATIONSHIP WITH EGO",
    year: "2021-22",
    relatedEvents: [
      {
        name: "UNPACKING STRESS AND ITS RELATIONSHIP WITH EGO",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [usimg1, usimg2],
      },
    ],
  },
  {
    id: 2233,
    img: vdimg1,
    name: "VISIT TO DISTRICT LEGAL SERVICES AUTHORITY",
    year: "2021-22",
    relatedEvents: [
      {
        name: "VISIT TO DISTRICT LEGAL SERVICES AUTHORITY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          vdimg1,
          vdimg2,
          vdimg3,
          vdimg4,
          vdimg5,
          vdimg6,
          vdimg7,
          vdimg8,
          vdimg9,
          vdimg10,
        ],
      },
    ],
  },
  {
    id: 2234,
    img: vyimg1,
    name: "VISIT TO YERWADAJAIL",
    year: "2021-22",
    relatedEvents: [
      {
        name: "VISIT TO YERWADAJAIL",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [
          vyimg1,
          vyimg2,
          vyimg3,
          vyimg4,
          vyimg5,
          vyimg6,
          vyimg7,
          vyimg8,
          vyimg9,
          vyimg10,
        ],
      },
    ],
  },
  {
    id: 2235,
    img: waclimg1,
    name: "WEBINAR ON ‘APPLICATION OF COPYRIGHT LAW IN TODAY'S DIGITAL AGE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘APPLICATION OF COPYRIGHT LAW IN TODAY'S DIGITAL AGE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [waclimg1],
      },
    ],
  },
  {
    id: 2236,
    img: wcpimg1,
    name: "WEBINAR ON ‘CARRYING PORN ON MOBILE PHONE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘CARRYING PORN ON MOBILE PHONE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wcpimg1],
      },
    ],
  },
  {
    id: 2237,
    img: wcmimg1,
    name: "WEBINAR ON ‘CONCEPT OF MEDICAL NEGLIGENCE UNDER TORT AND REMEDY UNDER CONSUMER PROTECTION LAW’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘CONCEPT OF MEDICAL NEGLIGENCE UNDER TORT AND REMEDY UNDER CONSUMER PROTECTION LAW’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wcmimg1],
      },
    ],
  },
  {
    id: 2238,
    img: wcrimg1,
    name: "WEBINAR ON ‘CONSTITUTIONAL RIGHTS TO BAIL ISSUES AND CHALLENGES",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘CONSTITUTIONAL RIGHTS TO BAIL ISSUES AND CHALLENGES",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wcrimg1],
      },
    ],
  },
  {
    id: 2239,
    img: wciimg1,
    name: "WEBINAR ON ‘CONTEMPORARY ISSUES AND TRENDS IN MARKETING",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘CONTEMPORARY ISSUES AND TRENDS IN MARKETING",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wciimg1],
      },
    ],
  },
  {
    id: 2240,
    img: wcipimg1,
    name: "WEBINAR ON ‘COPYRIGHT LAW FROM THE INDIAN PERSPECTIVE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘COPYRIGHT LAW FROM THE INDIAN PERSPECTIVE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wcipimg1],
      },
    ],
  },

  {
    id: 2241,
    img: wcvimg1,
    name: "WEBINAR ON ‘CURRICULUM VITAE MAKING",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘CURRICULUM VITAE MAKING",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wcvimg1],
      },
    ],
  },
  {
    id: 2242,
    img: wdcimg1,
    name: "WEBINAR ON ‘DEVELOPMENT OF CONSTITUTIONAL JURISPRUDENCE PHILOSOPHY OF JUDGES",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘DEVELOPMENT OF CONSTITUTIONAL JURISPRUDENCE PHILOSOPHY OF JUDGES",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wdcimg1],
      },
    ],
  },
  {
    id: 2243,
    img: wdpimg1,
    name: "WEBINAR ON ‘DIRECTIVE PRINCIPLES AND FUNDAMENTAL DUTIES",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘DIRECTIVE PRINCIPLES AND FUNDAMENTAL DUTIES",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wdpimg1],
      },
    ],
  },
  {
    id: 2244,
    img: fstimg1,
    name: "WEBINAR ON ‘FEDERAL STRUCTURE OF THE TEXTUAL CONSTITUTION OF INDIA",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘FEDERAL STRUCTURE OF THE TEXTUAL CONSTITUTION OF INDIA",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [fstimg1],
      },
    ],
  },
  {
    id: 2245,
    img: wfrimg1,
    name: "WEBINAR ON ‘FUNDAMENTALS OF RESEARCH METHODOLOGY",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘FUNDAMENTALS OF RESEARCH METHODOLOGY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wfrimg1],
      },
    ],
  },
  {
    id: 2246,
    img: whuimg1,
    name: "WEBINAR ON ‘HINDU UNDIVIDED FAMILY AN OVERVIEW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘HINDU UNDIVIDED FAMILY AN OVERVIEW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [whuimg1],
      },
    ],
  },
  {
    id: 2247,
    img: whpimg1,
    name: "WEBINAR ON ‘HOW TO PREPARE FOR NAAC",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘HOW TO PREPARE FOR NAAC",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [whpimg1],
      },
    ],
  },
  {
    id: 2248,
    img: wimimg1,
    name: "WEBINAR ON ‘IMPORTANCE OF VIRTUAL MEETING’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘IMPORTANCE OF VIRTUAL MEETING’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wimimg1],
      },
    ],
  },
  {
    id: 2249,
    img: wicimg1,
    name: "WEBINAR ON ‘INTERNATIONAL LAW ASPECTS OF COUNTERING TERRORISM",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘INTERNATIONAL LAW ASPECTS OF COUNTERING TERRORISM",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wicimg1],
      },
    ],
  },
  {
    id: 2250,
    img: wipimg1,
    name: "WEBINAR ON ‘INTESTATE SUCCESSION UNDER PERSONAL LAWS IN INDIA",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘INTESTATE SUCCESSION UNDER PERSONAL LAWS IN INDIA",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wipimg1],
      },
    ],
  },

  {
    id: 2251,
    img: wlrimg1,
    name: "WEBINAR ON ‘INTRODUCTION TO LEGAL RESEARCH WITH SCC ONLINE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘INTRODUCTION TO LEGAL RESEARCH WITH SCC ONLINE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wlrimg1],
      },
    ],
  },
  {
    id: 2252,
    img: wipvimg1,
    name: "WEBINAR ON ‘INTRODUCTION TO PENOLOGY AND VICTIMOLOGY’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘INTRODUCTION TO PENOLOGY AND VICTIMOLOGY’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wipvimg1],
      },
    ],
  },
  {
    id: 2253,
    img: wllimg1,
    name: "WEBINAR ON ‘LABOUR LAW REFORMS IN INDIA",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘LABOUR LAW REFORMS IN INDIA",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wllimg1],
      },
    ],
  },
  {
    id: 2254,
    img: wlgimg1,
    name: "WEBINAR ON ‘LAW RELATING TO GEOGRAPHICAL INDICATION (GI) IN INDIA’",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘LAW RELATING TO GEOGRAPHICAL INDICATION (GI) IN INDIA’",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wlgimg1],
      },
    ],
  },
  {
    id: 2255,
    img: wldimg1,
    name: "WEBINAR ON ‘LEGAL DIMENSIONS OF CONTRACT",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘LEGAL DIMENSIONS OF CONTRACT",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wldimg1],
      },
    ],
  },
  {
    id: 2256,
    img: wmcimg1,
    name: "WEBINAR ON ‘MAKE A CAREER IN LAW PREPARE FOR PLACEMENT",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘MAKE A CAREER IN LAW PREPARE FOR PLACEMENT",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wmcimg1],
      },
    ],
  },
  {
    id: 2257,
    img: wmuimg1,
    name: "WEBINAR ON ‘MARRIAGE UNDER HINDU LAW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘MARRIAGE UNDER HINDU LAW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wmuimg1],
      },
    ],
  },
  {
    id: 2258,
    img: wrbimg1,
    name: "WEBINAR ON ‘RELATIONSHIP BETWEEN INTERNATIONAL LAW AND MUNICIPAL LAW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘RELATIONSHIP BETWEEN INTERNATIONAL LAW AND MUNICIPAL LAW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wrbimg1],
      },
    ],
  },
  {
    id: 2259,
    img: wmpimg1,
    name: "WEBINAR ON ‘STRATEGY OF MANAGING PERSONAL FINANCES INCLUDING MUTUAL FUNDS DURING AND AFTER COVID 19",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘STRATEGY OF MANAGING PERSONAL FINANCES INCLUDING MUTUAL FUNDS DURING AND AFTER COVID 19",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wmpimg1],
      },
    ],
  },
  {
    id: 2260,
    img: wtmimh1,
    name: "WEBINAR ON ‘TRADE MARK LAW IN INDIA AN OVERVIEW",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON ‘TRADE MARK LAW IN INDIA AN OVERVIEW",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wtmimh1],
      },
    ],
  },
  {
    id: 2261,
    img: wowimg1,
    name: "Webinar on overview of Will",
    year: "2021-22",
    relatedEvents: [
      {
        name: "Webinar on overview of Will",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wowimg1],
      },
    ],
  },

  {
    id: 2262,
    img: waiimg1,
    name: "WEBINAR ON THE ABC OF INCOME TAX",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WEBINAR ON THE ABC OF INCOME TAX",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [waiimg1],
      },
    ],
  },
  {
    id: 2263,
    img: wsiimg1,
    name: "WORKSHOP ON ‘SOCIAL INTELLIGENCE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "WORKSHOP ON ‘SOCIAL INTELLIGENCE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [wsiimg1, wsiimg2],
      },
    ],
  },

  {
    id: 2264,
    img: ydimg1,
    name: "YOGA DAY CELEBRATED ON 21ST JUNE",
    year: "2021-22",
    relatedEvents: [
      {
        name: "YOGA DAY CELEBRATED ON 21ST JUNE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ydimg1, ydimg2],
      },
    ],
  },

  // 2022-23

  {
    id: 232,
    img: InC1img23,
    name: "1ST INTER- COLLEGE MUN 2023",
    year: "2022-23",
    relatedEvents: [
      {
        name: "1ST INTER- COLLEGE MUN 2023",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [InC1img23, InC2img23],
      },
    ],
  },

  {
    id: 233,
    img: BOV1img23,
    name: "A VISIT TO BOMBAY STOCK EXCHANGE WAS ORGANIZED ON 1ST MARCH 2023",
    year: "2022-23",
    relatedEvents: [
      {
        name: "A VISIT TO BOMBAY STOCK EXCHANGE WAS ORGANIZED ON 1ST MARCH 2023",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [BOV1img23, BOV2img23],
      },
    ],
  },

  {
    id: 234,
    img: ARD1img23,
    name: "ARMY DAY CELEBRATION",
    year: "2022-23",
    relatedEvents: [
      {
        name: "ARMY DAY CELEBRATION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ARD1img23, ARD2img23, ARD3img23, ARD4img23, ARD5img23],
      },
    ],
  },

  {
    id: 235,
    img: ALCQ1img23,
    name: "ARMY LAW COLLEGE, PUNE ORGANISED THE ‘QUEST INDICIA’ 2ND NATIONAL MOOT COURT",
    year: "2022-23",
    relatedEvents: [
      {
        name: "ARMY LAW COLLEGE, PUNE ORGANISED THE ‘QUEST INDICIA’ 2ND NATIONAL MOOT COURT",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ALCQ1img23, ALCQ2img23, ALCQ3img23],
      },
    ],
  },

  {
    id: 236,
    img: BLDC1img23,
    name: "BLOOD DONATION CAMP",
    year: "2022-23",
    relatedEvents: [
      {
        name: "BLOOD DONATION CAMP",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [BLDC1img23, BLDC2img23],
      },
    ],
  },

  {
    id: 237,
    img: CCD1img23,
    name: "CELEBRATING CONSTITUTION DAY",
    year: "2022-23",
    relatedEvents: [
      {
        name: "CELEBRATING CONSTITUTION DAY",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [CCD1img23, CCD2img23, CCD3img23, CCD4img23],
      },
    ],
  },

  {
    id: 238,
    img: FAW1img23,
    name: "FAREWELL OF 5TH YEAR STUDENTS",
    year: "2022-23",
    relatedEvents: [
      {
        name: "FAREWELL OF 5TH YEAR STUDENTS",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [FAW1img23, FAW2img23, FAW3img23, FAW4img23],
      },
    ],
  },

  {
    id: 239,
    img: NSS1img23,
    name: "NSS CAMP",
    year: "2022-23",
    relatedEvents: [
      {
        name: "NSS CAMP",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [NSS1img23, NSS2img23, NSS3img23],
      },
    ],
  },

  {
    id: 2310,
    img: RCA1img23,
    name: "REPORT ON CRITICAL ANALYSIS OF RECENT TRENDS IN MARKETING",
    year: "2022-23",
    relatedEvents: [
      {
        name: "REPORT ON CRITICAL ANALYSIS OF RECENT TRENDS IN MARKETING",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [RCA1img23, RCA2img23],
      },
    ],
  },

  {
    id: 2311,
    img: ANW1img23,
    name: "THE ART OF NETWORKING AND WRITING EFFECTIVE EMAILS TO LAND JOBS",
    year: "2022-23",
    relatedEvents: [
      {
        name: "THE ART OF NETWORKING AND WRITING EFFECTIVE EMAILS TO LAND JOBS",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [ANW1img23, ANW2img23],
      },
    ],
  },

  {
    id: 2312,
    img: YOGA1img23,
    name: "YOGA DAY CELEBRATION",
    year: "2022-23",
    relatedEvents: [
      {
        name: "YOGA DAY CELEBRATION",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [YOGA1img23, YOGA2img23, YOGA3img23],
      },
    ],
  },

  {
    id: 2313,
    img: VRBI1img23,
    name: "VISIT TO RBI MUSEUM AND BSE",
    year: "2022-23",
    relatedEvents: [
      {
        name: "VISIT TO RBI MUSEUM AND BSE",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [VRBI1img23, VRBI2img23],
      },
    ],
  },

  {
    id: 2314,
    img: VCAS1img23,
    name: "VISIT BY CHIEF OF STAFF",
    year: "2022-23",
    relatedEvents: [
      {
        name: "VISIT BY CHIEF OF STAFF",
        detail:
          "Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr Lorem ipsum dolor sit consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetut, consetetur sadipscing elitr.",
        Images: [VCAS1img23, VCAS2img23],
      },
    ],
  },

  // 2023-24

  {
    id: 241,
    img: symp1img24,
    name: "Symposium on “Pathway to Success for Aspiring Lawyers in the Contemporary Era Symposium”",
    year: "2023-24",
    relatedEvents: [
      {
        name: "Symposium on “Pathway to Success for Aspiring Lawyers in the Contemporary Era Symposium”",
        detail:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
        Images: [
          symp1img24,
          symp2img24,
          symp3img24,
          symp4img24,
          symp5img24,
          symp6img24,
        ],
      },
    ],
  },
  {
    id: 242,
    img: field1img24,
    name: "Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 2024",
    year: "2023-24",
    relatedEvents: [
      {
        name: "Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 2024",
        detail:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
        Images: [
          field1img24,
          field2img24,
          field3img24,
          field4img24,
          field5img24,
        ],
      },
    ],
  },
  // {
  //   id: 243,
  //   img: tatvaveda1img24,
  //   name: "RELEASE OF JALCP (TATVAVEDA)",
  //   year: "2023-24",
  //   relatedEvents: [
  //     {
  //       name: "RELEASE OF JALCP (TATVAVEDA)",
  //       detail:
  //         "Lorem ipsum dolor sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
  //       Images: [
  //         tatvaveda1img24,
  //         tatvaveda2img24,
  //         tatvaveda3img24,
  //         tatvaveda4img24,
  //       ],
  //     },
  //   ],
  // },
];
