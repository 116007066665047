import React, { useEffect } from "react";
import form from "../../assests/images/alumni/ALC-ALUMNI-MEMBERSHIP-REGISTRATION-FORM_page-0001.jpg";
import coimg from "../../assests/images/teaching/teaching-staff23_10_4.png";
import mail from "../../assests/images/alumni/email.png";
import pdf1 from "../../assests/images/pdf/ALUMNI ASSOCIATION OF ALC.pdf";
// import pdf1 from "../../assests/images/pdf/ALUMNI ASSOCIATION OF ALC.pdf";
// D:\default DOWNLOADS\Projects\alcstatic\New folder\src\assests\images\pdf\ALUMNI ASSOCIATION OF ALC.pdf
export default function Coordinator() {
  useEffect(() => {
    document.title = "Download Alumni Membership Form";
  }, []);
  return (
    <div className="container d-flex flex-wrap">
      <div className="col-lg-6 col-sm-12 mt-4">
        <p className="progsubhead">Alumni Registration Form:</p>
        <div className="formdiv col-6 col-sm-5">
          <a href={pdf1} download={pdf1}>
            <img src={form} alt="" className="img-fluid p-2" />
          </a>
        </div>
      </div>
      <div className="col-lg-6 col-sm-12 mt-4">
        <p className="progsubhead">Connect to Co-ordinator:</p>
        <div className="teamcarddiv">
          <div className="teamcard">
            <img
              src={coimg}
              className="card-img-top img-fluid"
              alt="Card Image"
            />
          </div>
          <div className="card-body px-1 py-2 ">
            <h5 className="teamname">Mr. Nagesh Kanki</h5>
            <p className="destiny ">Assistant Professor</p>
          </div>
        </div>
        <div className="px-3 progtext">
          <img src={mail} alt=" " className="img-fluid col-1" />
          <span className="col-11"> nageshkankialc@gmail.com</span>
        </div>
      </div>
    </div>
  );
}
