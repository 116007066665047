import React from "react";
import eventicon from "../../../assests/images/others/Group 1777.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import img1 from "../../../assests/images/events/2023-24/Field Marshal SHFJ/NMK_5142.JPG";
import img2 from "../../../assests/images/events/2023-24/RELEASE OF JALCP/Picture1.jpg";
import img3 from "../../../assests/images/events/2023-24/Symposium/IMG_9864.JPG";
import { Link } from "react-router-dom";
export default function Event() {
  const eventData = [
    {
      img: img1,
      content: {
        title:
          "Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 2024",
        date: " 10th May 2024",
        detail:
          "The Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 2024  ...",
      },
    },
    // {
    //   img: img2,
    //   content: {
    //     title: "RELEASE OF JALCP  (TATVAVEDA).",
    //     date: "5th May 2024",
    //     detail:
    //       "On 23rd May 2024, The Research and Journal Cell of Army Law College..",
    //   },
    // },
    {
      img: img3,
      content: {
        title:
          "Symposium on “Pathway to Success for Aspiring Lawyers in the Contemporary Era Symposium”",
        date: "23rd May 2024",
        detail: "On 5th May 2024, the Internship and Placement cell...",
      },
    },
  ];
  return (
    <div>
      <div className="headerdiv1">
        <img src={eventicon} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2>Events</h2>
          <Link to="/events" className="linerm">
            {" "}
            <p className="mb-0">
              All Events{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event">
        {eventData.map((event, index) => (
          <div
            className="event-content d-flex px-2 py-3 justify-content-between"
            key={index}
          >
            <div className="col-5 p-2">
              <img src={event.img} alt="" className="img-fluid" />
            </div>
            <div className="col-7">
              <h2 className="event-title">{event.content.title}</h2>
              <p className="event-date">{event.content.date}</p>
              <p className="event-detail">{event.content.detail}</p>
              {/* <p className='event-btn'>Read more</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
