import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { TeachingStaffData, NonTeachingStaffData } from "../../StaffData";

export default function Intro() {
  const [activeTab, setActiveTab] = useState("biography");
  const { id, staffType } = useParams();
  const staffMember =
    staffType === "teaching"
      ? TeachingStaffData.find((staff) => staff.id === parseInt(id))
      : NonTeachingStaffData.find((staff) => staff.id === parseInt(id));

  if (!staffMember) {
    return <div>No staff data available</div>;
  }

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="staffintro ">
        <div className="intro d-flex flex-wrap">
          {staffMember.img ? (
            <div className="introimg p-2">
              <img src={staffMember.img} alt="" className="img-fluid" />
            </div>
          ) : (
            <div></div>
          )}

          <div className="staffDetails m-4">
            <p className="staffname">
              {" "}
              {staffMember.name ? (
                staffMember.name
              ) : (
                <span>Name not available</span>
              )}
            </p>
            <p className="staffpost">
              {staffMember.post ? (
                staffMember.post
              ) : (
                <span>Post not available</span>
              )}
            </p>
            <p className="staffedu">
              {staffMember.qualification ? (
                staffMember.qualification
              ) : (
                <span>Qualification not available</span>
              )}
            </p>
            <p className="staffedu">{staffMember.qualification1 || " "}</p>
            <p className="staffemail">
              {staffMember.email ? (
                staffMember.email
              ) : (
                <span>Email not available</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 bio">
        <div className="d-flex biobtn ">
          <p
            className={`biography ${activeTab === "biography" ? "active" : ""}`}
            onClick={() => handleClick("biography")}
          >
            Biography
          </p>
          <p
            className={`biography ${activeTab === "research" ? "active" : ""}`}
            onClick={() => handleClick("research")}
          >
            Research & Publications
          </p>
          {/* <p
            className={`biography ${activeTab === "presentation" ? "active" : ""}`}
            onClick={() => handleClick("presentation")}
          >
            Paper Presentation
          </p> */}
        </div>
        {activeTab === "biography" && (
          <p className="biodetail mt-3">
            {staffMember.biography ? (
              staffMember.biography
            ) : (
              <span>No biography data available</span>
            )}
          </p>
        )}
        {activeTab === "research" && (
          <>
            <div className="biodetail mt-3">
              <>
                {staffMember?.research?.length > 0 ? (
                  <ul>
                    {staffMember.research.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No research data available</p>
                )}
              </>
            </div>
            <div className="biodetail mt-3">
              <>
                {staffMember?.presentation?.length > 0 ? (
                  <>
                    <h4>Paper Presentation:</h4>
                    <ul>
                      {staffMember.presentation.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>
            <div className="biodetail mt-3">
              <>
                {staffMember?.course?.length > 0 ? (
                  <>
                    <h4>Course:</h4>
                    <ul>
                      {staffMember.course.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p></p>
                )}
              </>
            </div>
          </>
        )}
      </div>
    </>
  );
}
